export default class Home
{
	constructor()
	{
		this.initHome();
	}

	initHome()
	{
		this.slideCtrls = document.querySelectorAll('.slide-info .next');
		this.slideCtrls.forEach(ctrl => ctrl.addEventListener('click', this.nextSlide.bind(this)));

		this.animatorTimeout = null;
		this.transitionTimeout = null;
		this.classTimeout = null;
	}

	nextSlide(e)
	{
		const type = e.target.dataset.type;

		this.animator = document.querySelector('.' + type + ' .animator');

		this.slides = document.querySelectorAll('.' + type + ' .slide');
		this.infos = document.querySelectorAll('.' + type + '-infos .slide-info');

		this.active = document.querySelector('.' + type + ' .slide.active');
		this.activeInfo = document.querySelector('.' + type + '-infos .slide-info.active');

		this.bgs = document.querySelectorAll('.bgs[data-type="' + type + '"] .bg');
		this.activeBg = document.querySelector('.bgs[data-type="' + type + '"] .bg.active');

		this.activeIndex = this.getElementIndex(this.active);
		this.nextIndex = this.slides[this.activeIndex + 1] ? this.activeIndex + 1 : 0;

		this.next = this.slides[this.nextIndex];
		this.nextInfo = this.infos[parseInt(this.next.dataset.i)];

		this.active.classList.remove('active');
		this.next.classList.add('active');

		this.activeInfo.classList.remove('active');
		this.activeInfo.classList.add('remove');

		this.activeBg.classList.remove('active');
		this.bgs[parseInt(this.next.dataset.i)].classList.add('active');

		this.animator.style.transform = 'translate3d(-' + (this.nextIndex * 100) + '%, 0, 0)';

		clearTimeout(this.animatorTimeout);
		this.animatorTimeout = setTimeout(() =>
		{
			this.animator.classList.add('no-transition');
			this.animator.style.transform = 'translate3d(0, 0, 0)';

			this.active.parentNode.appendChild(this.active);
		}, 900);

		clearTimeout(this.transitionTimeout);
		this.transitionTimeout = setTimeout(() => this.animator.classList.remove('no-transition'), 950);

		clearTimeout(this.classTimeout);
		this.classTimeout = setTimeout(() =>
		{
			this.activeInfo.classList.remove('remove');
			this.nextInfo.classList.add('active');
		}, 450);
	}

	getElementIndex(node)
	{
		let index = 0;

		while(node = node.previousElementSibling)
			index++;

		return index;
	}
}
