export default class ServiceWorker
{
	constructor()
	{
		if('serviceWorker' in navigator && navigator.serviceWorker && !window.devMode)
			this.initServiceWorker();
	}

	initServiceWorker()
	{
		navigator.serviceWorker.register(window.baseUrl + 'sw.js', {
			scope: '/'
		});
	}
}
