import Polyfills from './Polyfills';

import Animate from 'ether-animate';
import Home from './Home';
import Hero from './Hero';
import Lazy from 'ether-lazy';
import ServiceWorker from './ServiceWorker';

function init()
{
	new Polyfills();

	new Animate();
	new Home();
	new Hero();
	new Lazy();
	new ServiceWorker();
}

init();
