export default class Hero
{
	constructor()
	{
		this.initHero();
	}

	initHero()
	{
		this.timer = 7000;

		this.heroes = document.querySelectorAll('#hero .hero');
		this.ctrls = document.querySelectorAll('#hero .ctrls a');

		setTimeout(() => this.ctrls[0].classList.add('active'), 50);
		this.ctrls.forEach(ctrl => ctrl.addEventListener('click', this.goTo.bind(this)));

		this.timeout = setTimeout(this.goTo.bind(this), this.timer);

		this.disabled = false;
	}

	goTo(e)
	{
		if(!this.disabled)
		{
			this.active = document.querySelector('#hero .ctrls a.active');

			this.activeIndex = this.getElementIndex(this.active);
			this.nextIndex = this.ctrls[this.activeIndex + 1] ? this.activeIndex + 1 : 0;

			if(e !== undefined && e.target)
			{
				this.disabled = true;
				this.nextIndex = parseInt(e.target.dataset.index);

				setTimeout(() => this.disabled = false, 1500);
			}

			this.next = this.ctrls[this.nextIndex];

			this.activeHero = this.heroes[this.activeIndex];
			this.nextHero = this.heroes[this.nextIndex];

			this.active.classList.add('remove');
			this.next.classList.add('active');

			this.activeHero.classList.add('remove');

			clearTimeout(this.removeTimeout);
			this.removeTimeout = setTimeout(() =>
			{
				this.active.classList.remove('remove');
				this.active.classList.remove('active');

				this.nextHero.classList.add('z-index');
				this.nextHero.classList.add('active');
			}, 500);

			clearTimeout(this.classTimeout);
			this.classTimeout = setTimeout(() =>
			{
				this.activeHero.classList.remove('remove');
				this.activeHero.classList.remove('active');

				this.nextHero.classList.remove('z-index');
			}, 1500);

			clearTimeout(this.timeout);
			this.timeout = setTimeout(this.goTo.bind(this), this.timer);
		}
	}

	getElementIndex(node)
	{
		let index = 0;

		while(node = node.previousElementSibling)
			index++;

		return index;
	}
}
